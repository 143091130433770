export const handleInput = (e, func) => {
  // e.preventDefault();
  func(e.target.value);
  sessionStorage.setItem(e.target.name, e.target.value);
};

export const calculateResults = async (e) => {
  // e.preventDefault();
  console.log("calculating the results");

  const data = () => {
    const heightunit = sessionStorage.getItem("heightunit");
    const height = sessionStorage.getItem("height");
    // 2 B IMPLEMENTED: get all data from the session storage

    return { heightunit, height };
  };

  const myData = data();
  console.log(JSON.stringify(myData));

  try {
    const response = await fetch(
      `${process.env.REACT_APP_MAIN_SERVER}/calculate/bikefit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(myData),
      }
    );
    console.log(response);
    const result = await response.json();
    console.log("Success:", result);
  } catch (error) {
    console.error("Error:", error);
  }
};
