import React from "react";
import { CalculateButton, BackButton } from "../components/Buttons";
import InputFormVideo from "../components/InputFormVideo";
import Modal from "../components/Modal";
import { motion } from "framer-motion";
import Scroll2Top from "../components/Scroll2Top";

export default function ShoulderWidth({ measures, setMeasures }) {
  const data = {
    id: 1,
    name: "shoulderwidth",
    unit: ["cm", "inch"],
    placeholderMetric: "e.g. 62",
    placeholderImperial: "e.g. 24.4",
    title: "shoulder width",
    video: (
      <iframe
        src="https://www.youtube.com/embed/bY8D5ixMP_A?si=CzdurlLQ0L0A9hUl"
        title="How to measure your shoulder width"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
    placeholderImage: "images/Screenshot_shoulderwidth.jpg",
    videoUrl: "https://www.youtube.com/watch?v=bY8D5ixMP_A",
    next: <CalculateButton />,
  };

  return (
    <>
      <Modal />
      <motion.main
        initial={{ opacity: 0, transition: { duration: 0.3 } }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        exit={{ opacity: 0, transition: { duration: 0.3 } }}
        // initial={{
        //   width: 0,
        // }}
        // animate={{ width: "100%", transition: { duration: 0.3 } }}
        // exit={{ x: window.innerWidth }}
      >
        <Scroll2Top />
        <BackButton side={"right"} />
        <InputFormVideo data={data} />
        <div className="pageBackground"></div>
      </motion.main>
    </>
  );
}

//
