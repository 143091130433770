import { useTranslation } from "react-i18next";

const lngs = {
  en: { nativeName: "ENGLISH" },
  de: { nativeName: "DEUTSCH" },
};

export default function Header() {
  const { t, i18n } = useTranslation();

  return (
    <header className="container-fluid">
      <nav
        className="navbar fixed-top navbar-expand-lg navbar-dark bs-body-bg-rgb"
        data-bs-theme="dark"
      >
        <div className="container-fluid d-flex justify-content-between">
          <div className="navbar-brand">
            <a href="/">
              <img
                id="logo"
                src="../images/Pedalytics_Logo.png"
                className="position-relative top-0 left-0"
                alt="pedalytics logo"
              ></img>
            </a>
          </div>
          <div className="menuContainer"></div>
        </div>
      </nav>
    </header>
  );
}
