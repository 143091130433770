import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConsentBanner, ConsentProvider } from "react-hook-consent";
import "./styles/index.css";
import "react-hook-consent/dist/styles/style.css";
import { Analytics } from "@vercel/analytics/react";

const ConsentOptions = {
  services: [
    {
      id: "technical",
      name: "Technical",
      description:
        "We are using an external library for the styling of our website. Also, we need to store the measures which you enter in the input fields inside your browsers storage. Without this, the app does not work. We are not storing personal information such as your name, mailaddress etc. ",
      scripts: [
        {
          id: "external-script",
          src: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js",
        },
      ],
      mandatory: true,
    },
    {
      id: "youTubeConsent",
      name: "YouTube",
      description:
        "We have embedded YouTube videos to show how to take your measures correctly. YouTube places cookies. If you don't wish this, you will not be able to see the video links.",
      scripts: [
        {
          id: "youtube-script",
          src: "https://www.youtube.com/iframe_api",
        },
        {
          id: "inseamlength",
          src: "https://www.youtube.com/embed/4Sofcy8msE0?si=1d-uwhX73XVeb-pi",
        },
        {
          id: "armlength",
          src: "https://www.youtube.com/embed/5BIIzvbOhrY?si=A8zjZEPYgKnKM6ok",
        },
        {
          id: "torsolength",
          src: "https://www.youtube.com/embed/RYNJJmBw9Fw?si=A-HJdvWGgXVH5QFR",
        },
        {
          id: "shoulderwidth",
          src: "https://www.youtube.com/embed/bY8D5ixMP_A?si=CzdurlLQ0L0A9hUl",
        },
      ],
    },
    // {
    //   id: "googleAnalyticsConsent",
    //   name: "Analytics",
    //   description:
    //     "We are using an Analytics tool for our marketing purposes. This way we can understand where our customers come from and how we can improve our service. The informaton transmitted is anonymous.",
    //   scripts: [{ id: "external-script", src: "" }],
    // },
  ],
  theme: "dark",
};

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConsentProvider options={ConsentOptions}>
        <App />
        <Analytics />
        <ConsentBanner
          settings={{
            hidden: false,
            label: "Settings",
            modal: { title: "Cookies" },
          }}
          decline={{ hidden: true, label: "Decline" }}
          approve={{ label: "I allow cookies" }}
        >
          <>
            We use cookies. You can decide which functions you want to agree to.
          </>
        </ConsentBanner>
      </ConsentProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//       <BrowserRouter>
//         <ConsentProvider options={ConsentOptions}>
//           <App />
//           <ConsentBanner decline={{ hidden: true }}>
//             <>
//               We use cookies. You can decide which functions you want to agree
//               to.
//             </>
//           </ConsentBanner>
//         </ConsentProvider>
//       </BrowserRouter>
//     </React.StrictMode>,
//     rootElement
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <BrowserRouter>
//         <ConsentProvider options={ConsentOptions}>
//           <App />
//           <ConsentBanner decline={{ hidden: true }}>
//             <>
//               We use cookies. You can decide which functions you want to agree
//               to.
//             </>
//           </ConsentBanner>
//         </ConsentProvider>
//       </BrowserRouter>
//     </React.StrictMode>,
//     rootElement
//   );
// }

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
