import React, { useState, useEffect } from "react";
import { handleInput } from "../utils/functions";
import { useConsent, ConsentContext } from "react-hook-consent";

export default function InputForm({ data }) {
  const [inputValue, setInputValue] = useState("");
  const [unit, setUnit] = useState("cm");

  const { hasConsent } = useConsent(ConsentContext);
  const youTubeConsent = "youTubeConsent";

  useEffect(() => {
    const storedValue = sessionStorage.getItem(data.name);
    if (storedValue !== null) {
      setInputValue(storedValue);
    }

    const storedKey = sessionStorage.getItem(`${data.name}unit`);
    if (storedKey !== null) {
      setUnit(storedKey);
    }
  }, [data.name]);

  return (
    <>
      <section
        id="singlePage"
        className="container d-flex flex-column justify-content-center overflow-visible"
      >
        <h2>
          {`What's your ${data.title}`}
          <span className="questionmark">?</span>
        </h2>
        <div className="container overflow-visible">
          <div className="row">
            <div className="col-12 col-md-6 col-5 py-4">
              {hasConsent(youTubeConsent) ? (
                <>
                  {" "}
                  <div
                    className="videoContainer"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "1px solid black",
                    }}
                  >
                    {data.video}{" "}
                  </div>
                </>
              ) : (
                <>
                  <div className="imageLinkContainer">
                    <a
                      href={data.videoUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-danger link2Spotify text-center align-center"
                      tabIndex="0"
                    >
                      Watch on YouTube
                    </a>
                    <img
                      src={data.placeholderImage}
                      alt="Video: How to measure my inseam length"
                      width="100%"
                      height="100%"
                      className="imageLink"
                    ></img>
                  </div>
                </>
              )}
            </div>
            <div className="col-12 col-md-6 col-5 ">
              <div
                className="d-flex flex-column align-items-center justify-content-evenly "
                style={{ height: "100%" }}
              >
                <div className="col-8">
                  <fieldset
                    className="mx-auto"
                    required
                    aria-label={`select a unit for your ${data.name}`}
                    style={{ width: "80%" }}
                  >
                    <div className="d-flex align-items-center">
                      <input
                        id="metric"
                        type="radio"
                        value={`${data.unit[0]}`}
                        name={`${data.name}unit`}
                        checked={unit === `${data.unit[0]}`}
                        onChange={(e) => {
                          handleInput(e, setUnit);
                        }}
                      ></input>
                      <label
                        htmlFor={`${data.unit[0]}`}
                      >{`${data.unit[0]}`}</label>
                    </div>
                    <div className="d-flex align-items-center">
                      <input
                        id="imperial"
                        type="radio"
                        value={`${data.unit[1]}`}
                        name={`${data.name}unit`}
                        checked={unit === `${data.unit[1]}`}
                        onChange={(e) => {
                          handleInput(e, setUnit);
                        }}
                      ></input>
                      <label
                        htmlFor={`${data.unit[1]}`}
                      >{`${data.unit[1]}`}</label>
                    </div>
                  </fieldset>
                </div>

                <input
                  type="number"
                  placeholder={
                    unit === "cm"
                      ? data.placeholderMetric
                      : data.placeholderImperial
                  }
                  aria-label={`${data.name}`}
                  name={`${data.name}`}
                  value={inputValue}
                  required
                  onChange={(e) => {
                    handleInput(e, setInputValue);
                  }}
                  style={{ backgroundColor: "white" }}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center p-4"
          style={{ overflow: "visible" }}
        >
          {data.next}
        </div>
      </section>
    </>
  );
}
