import { BackButton } from "../components/Buttons";
import Scroll2Top from "../components/Scroll2Top";

export default function Impressum() {
  return (
    <main id="impressum">
      <Scroll2Top />
      <section className="container d-flex flex-column justify-content-start align-items-start overflow-visible">
        <BackButton side={"right"} />
        <h1 className="py-5" style={{ padding: "5%", margin: "auto" }}>
          Impressum
        </h1>

        <h2 className="py-4" style={{ fontFamily: "Segoe UI" }}>
          Angaben gemäß § 5 TMG:
        </h2>
        <p>
          Diagnose Berlin<br></br>
          Raphael Jung<br></br>
          Bötzowstraße 14<br></br>
          10407 Berlin
        </p>
        <p>
          Telefon: 030 2000 40 34 <br></br>
          E-Mail: info@diagnose-berlin.de
        </p>

        <h3 className="py-4">Angaben zur Berufshaftpflichtversicherung</h3>
        <p>Name und Sitz der Gesellschaft</p>
        <p>
          Allianz Versicherungs-AG<br></br>10900 Berlin
        </p>
        <p>Geltungsraum der Versicherung: Deutschland</p>
        <h3 className="py-4">
          Verantwortlich für den Inhalt nach §55 Abs. 2 RStV:
        </h3>
        <p>
          Raphael Jung<br></br>
          Bötzowstraße 14<br></br>
          10407 Berlin
        </p>
        <h3 className="py-5">Fotos von:</h3>
        <p>
          Constantin Gerlach<br></br>Gormannstr. 18a <br></br>10119 Berlin
        </p>
        <a
          className="linkUnderline"
          href="http://constantingerlach.de/"
          target="_blank"
          rel="noreferrer"
        >
          www.constantingerlach.de
        </a>
        <p>
          Simon Hofmann<br></br>Wintergasse 26<br></br>69469 Weinheim
        </p>
        <a
          className="linkUnderline"
          href="https://o.offen-blen.de/"
          target="_blank"
          rel="noreferrer"
        >
          www.offen-blen.de
        </a>
        <p>
          Joshua Meissner<br></br>Litauer Str. 2<br></br>10245 Berlin
        </p>
        <a
          className="linkUnderline"
          href="https://joshuameissner.de/"
          target="_blank"
          rel="noreferrer"
        >
          www.joshuameissner.de
        </a>
        <p>Drew kaplan</p>
        <a
          className="linkUnderline"
          href="https://www.facebook.com/DrewKaplanPhotography"
          target="_blank"
          rel="noreferrer"
        >
          www.facebook.com/DrewKaplanPhotography
        </a>
      </section>
      <section className="container d-flex flex-column justify-content-start align-items-start overflow-visible">
        <h2 className="pb-4" style={{ fontFamily: "Segoe UI" }}>
          Haftung für Inhalte
        </h2>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
          hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </p>
        <h3 className="py-4">Haftung für Links</h3>
        <p>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <h3 className="py-4">Urheberrecht</h3>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
      </section>
      <section className="container d-flex flex-column justify-content-start align-items-start overflow-visible">
        <h2 className="py-4" style={{ fontFamily: "Segoe UI" }}>
          Datenschutz
        </h2>
        <p>
          VERORDNUNG (EU) 2016/679 des Europäischen Parlaments und des Rates vom
          27. April 2016 zum Schutz natürlicher Personen bei der Verarbetung
          personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der
          Richtlinie 95/46/EG (Datenschutz-Grundverordnung): DSGVO zum
          25.05.2018 EUR-Lex, Access to European Union Law
        </p>
        <p>
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
          ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
          von Werbeinformationen, etwa durch Spam-Mails, vor.
        </p>
        <p>
          Quellenangaben: Disclaimer von eRecht24, dem Portal zum Internetrecht
          von Rechtsanwalt Sören Siebert
        </p>
        <h3 className="py-4">
          Datenschutzerklärung für die Nutzung von Google Analytics
        </h3>
        <p>
          Diese Website nutzt Funktionen des Webanalysedienstes Google
          Analytics. Anbieter ist die Google Inc. 1600 Amphitheatre Parkway
          Mountain View, CA 94043, USA. Google Analytics verwendet sog.
          „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert
          werden und die eine Analyse der Benutzung der Website durch Sie
          ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
          Benutzung dieser Website werden in der Regel an einen Server von
          Google in den USA übertragen und dort gespeichert.
        </p>
        <p>
          Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite
          wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten
          der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
          über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
          Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
          den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
          Website wird Google diese Informationen benutzen, um Ihre Nutzung der
          Website auszuwerten, um Reports über die Websiteaktivitäten
          zusammenzustellen und um weitere mit der Websitenutzung und der
          Internetnutzung verbundene Dienstleistungen gegenüber dem
          Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von
          Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
          Google zusammengeführt.
        </p>
        <p>
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch das Cookie erzeugten und
          auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem sie das unter
          <a
            className="linkUnderline"
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noreferrer"
          >
            diesem Link
          </a>
          verfügbare Browser-Plugin herunterladen und installieren:
        </p>
        <h3 className="py-4">Auskunft, Löschung, Sperrung</h3>
        <p>
          Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre
          gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
          und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen
          zum Thema personenbezogene Daten können Sie sich jederzeit unter der
          im Impressum angegebenen Adresse an uns wenden.
        </p>
      </section>
    </main>
  );
}
