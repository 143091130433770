import Header from "../components/Header";
import Footer from "../components/Footer";
import { BackButton } from "../components/Buttons";

export default function Errorpage404() {
  return (
    <>
      <Header />
      <section className="container-fluid d-flex flex-column align-items-center justify-content-center">
        <h1>404</h1>
        <h3>Oops, this should not have happened!</h3>
        <BackButton style={{ margin: "15%" }} />
      </section>
      <Footer />
    </>
  );
}
