import { StartButton, StartButtonDisabled } from "../components/Buttons";
import { motion } from "framer-motion";
import { useConsent, ConsentContext } from "react-hook-consent";
import Scroll2Top from "../components/Scroll2Top";
// import Lottie from "lottie-react";
// import animation_lmst467v from "../utils/lotties/animation_lmst467v.json";

export default function LandingPage() {
  const { consent, isBannerVisible } = useConsent();
  console.log("consent", consent);
  console.log(isBannerVisible);
  const { toggleBanner } = useConsent(ConsentContext);
  // const technicalConsent = "technical";
  return (
    <motion.main
      initial={{ opacity: 0, transition: { duration: 1 } }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <Scroll2Top />
      <section
        id="landingPage"
        className="container-fluid d-flex flex-column justify-content-center overflow-visible p-0"
      >
        <div className="container-fluid d-flex flex-column justify-content-center overflow-visible landingpageContainer">
          <div
            className="col-11 col-sm-7 col-md-6 col-lg-4 p-3 overflow-visible"
            // style={{ height: "70%" }}
          >
            <h1>Finding your perfectly matching bike has never been easier.</h1>
            <StartButton path={"/height"} />
            {/* {consent.includes("technical") ? (
              <StartButton path={"/height"} />
            ) : (
              <StartButtonDisabled />
            )} */}
          </div>
          {/* <div
            className="col-11 col-sm-7 col-md-6 col-lg-4 p-3 position-relative"
            // className="d-flex flex-column justify-content-end align-items-start"
            style={{ top: "15%" }}
          >
            <p className="text-center">scroll for more info</p>
            <Lottie
              className="lottieArrow "
              animationData={animation_lmst467v}
              style={{ height: 100 }}
            />
          </div> */}
        </div>
      </section>
      {/* <section
        className="container-fluid d-flex flex-column justify-content-center align-items-center p-0"
        style={{ height: "auto" }}
      >
        <h2 className="py-5">This is a headline</h2>
        <p className="col-12 col-sm-10 col-md-8 text-center p-2">
          Lorem ipsum dolor sit amet, 
        </p>
        <h2 className="py-5">How does it work?</h2>
        <p className="col-12 col-sm-10 col-md-8 text-center p-2">
          sed diam nonummy nibh euismod tincidunt 
        </p>
        <h2 className="py-5">How much is it?</h2>
        <p className="col-12 col-sm-10 col-md-8 text-center p-2">
          sed diam nonummy nibh euismod 
        </p>
        <StartButton path={"/height"} />
      </section> */}
    </motion.main>
  );
}
