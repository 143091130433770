import React, { useState } from "react";
// import { submitMail2 } from "../utils/submitMail2DB";

export default function Modal() {
  const [emailAddress, setEmailAddress] = useState("");
  const [modalContent, setModalContent] = useState("");

  const submitMail = async () => {
    if (emailAddress.trim().length === 0) {
      setModalContent("Please provide a valid email address.");
    } else {
      const myData = { mail: emailAddress };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_MAIN_SERVER}/submitmail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(myData),
          }
        );
        // console.log(response);
        const result = await response.json();
        setModalContent("Thanks! Your mail was successfully added.");
      } catch (error) {
        setModalContent("An error occurred. Please try again later.");
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setEmailAddress(e.target.value);
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Thank you for your interest!
            </h5>
            <button
              type="button"
              className="btn-close btn-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>
              We are still in the process of setting up our service and need a
              little more time... Tell us your e-mail address and we'll notifiy
              you as soon as we're ready for you.
            </p>
            <form
              onSubmit={(e) => {
                submitMail(e);
              }}
            >
              <input
                type="email"
                placeholder="your email address"
                value={emailAddress}
                required
                onChange={(e) => {
                  handleChange(e);
                }}
              ></input>
            </form>
          </div>
          {modalContent && (
            <div className="modal-footer">
              <p>{modalContent}</p>
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark"
              data-bs-dismiss="modal"
            >
              No thanks
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                submitMail();
              }}
            >
              Yes, notify me
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
