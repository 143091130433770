import { useNavigate, Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { calculateResults } from "../utils/functions";
import { useConsent } from "react-hook-consent";

export function CalculateButton() {
  const navigate = useNavigate();

  return (
    <>
      <button
        type="button"
        className="btn btn-dark outlined"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        style={{ width: "300px" }}
        onClick={(e) => {
          calculateResults(e);
          console.log("clicked");
          // navigate("/result");
        }}
      >
        Calculate
      </button>
    </>
  );
}

export function ContinueButton({ path }) {
  return (
    <Link
      className="button btn btn-dark"
      to={`${path}`}
      style={{ width: "300px" }}
    >
      Continue
    </Link>
  );
}

export function StartButton({ path }) {
  return (
    <Link
      className="button btn btn-dark"
      to={`${path}`}
      style={{ width: "300px" }}
    >
      Start
    </Link>
  );
}

export function StartButtonDisabled() {
  const { isBannerVisible, toggleBanner } = useConsent();
  return (
    <button
      className="button btn btn-dark disabled"
      aria-disabled="true"
      onClick={() => (isBannerVisible ? null : toggleBanner())}
    >
      Start
    </button>
    // <Link
    //   className="button btn btn-dark disabled"
    //   to={`${path}`}
    //   style={{ width: "300px" }}
    //   aria-disabled="true"
    //   title="we need your consent before you can start"
    // >
    //   Start
    // </Link>
  );
}

export function BackButton({ side }) {
  const navigate = useNavigate();

  return (
    <button
      className={side === "right" ? "btn btn-light right" : "btn btn-light"}
      style={{
        width: "120px",
        position: "fixed",
        right: "0",
        top: "0",
        zIndex: "1050",
        margin: "10px",
      }}
      onClick={() => navigate(-1)}
    >
      <BiArrowBack style={{ verticalAlign: "sub" }} /> back
    </button>
  );
}
