import InputFormVideo from "../components/InputFormVideo";
import { ContinueButton, BackButton } from "../components/Buttons";
import { motion } from "framer-motion";
import Scroll2Top from "../components/Scroll2Top";

export default function InseamLength({ measures, setMeasures }) {
  const data = {
    id: 1,
    name: "inseamlength",
    unit: ["cm", "inch"],
    placeholderMetric: "e.g. 90",
    placeholderImperial: "e.g. 35.4",
    title: "inseam length",
    video: (
      <iframe
        src="https://www.youtube.com/embed/4Sofcy8msE0?si=1d-uwhX73XVeb-pi"
        title="How to measure your inseam length using the book method."
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
    placeholderImage: "images/Screenshot_inseamlength.jpg",
    videoUrl: "https://www.youtube.com/watch?v=4Sofcy8msE0",
    next: <ContinueButton path={"/armlength"} />,
  };

  return (
    <motion.main
      initial={{ opacity: 0, transition: { duration: 0.3 } }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <Scroll2Top />
      <BackButton side={"right"} />
      <InputFormVideo data={data} />
      <div className="pageBackground"></div>
    </motion.main>
  );
}
