import { BackButton } from "../components/Buttons";
import Scroll2Top from "../components/Scroll2Top";

export default function DataProtection() {
  return (
    <main>
      <Scroll2Top />
      <section className="container  d-flex flex-column justify-content-start align-items-start overflow-visible">
        <BackButton side={"right"} />
        <h1 className="py-5" style={{ padding: "5%", margin: "auto" }}>
          Datenschutzerklärung
        </h1>
        <h2 className="py-4" style={{ fontFamily: "Segoe UI" }}>
          Allgemeiner Hinweis und Pflichtinformationen
        </h2>
        <h3 className="py-4">Benennung der verantwortlichen Stelle</h3>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist: Diagnose Berlin<br></br>
          Raphael Jung<br></br>
          Gustav-Adolf-Straße 151<br></br>
          13086 Berlin
        </p>
        <p>
          Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        </p>
        <h3 className="py-4">Geltungsbereich</h3>
        <p>
          Diese Datenschutzerklärung soll die Nutzer dieser Website gemäß
          Bundesdatenschutzgesetz und Telemediengesetz über die Art, den Umfang
          und den Zweck der Erhebung und Verwendung personenbezogener Daten
          durch den Webseitenbetreiber, Jung & Krott GbR, informieren.
        </p>
        <p>
          Der Webseitenbetreiber nimmt Ihren Datenschutz sehr ernst und
          behandelt Ihre personenbezogenen Daten vertraulich und entsprechend
          der gesetzlichen Vorschriften. Bedenken Sie, dass die Datenübertragung
          im Internet grundsätzlich mit Sicherheitslücken bedacht sein kann. Ein
          vollumfänglicher Schutz vor dem Zugriff durch Fremde ist nicht
          realisierbar.
        </p>
        <h3 className="py-4">Umgang mit Kontaktdaten</h3>
        <p>
          Ihre Kontaktaufnahme mit dem Webseitenbetreiber erfolgt freiwillig.
          Nehmen Sie mit dem Webseitenbetreiber durch die angebotenen
          Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben unter
          Umständen gespeichert, damit auf diese zur Bearbeitung und
          Beantwortung Ihrer Anfrage zurückgegriffen werden kann. Ohne Ihre
          Einwilligung werden diese Daten nicht an Dritte weitergegeben.
        </p>
        <h3 className="py-4">
          Iher Rechte auf Auskunft, Berichtigung, Löschung und Widerruf der
          Datenverarbeitung
        </h3>
        <p>
          Sie haben das Recht, jederzeit Auskunft über Ihre bei dem
          Websitebetreiber gespeicherten personenbezogenen Daten zu erhalten.
          Ebenso haben Sie das Recht auf Berichtigung oder, abgesehen von der
          Datenspeicherung zur Geschäftsabwicklung, Löschung Ihrer
          personenbezogenen Daten. Bitte wenden Sie sich dazu an den
          Webseitenbetreiber. Die Kontaktdaten finden Sie am Schluss dieser
          Erklärung. Sie können Änderungen oder den Widerruf einer Einwilligung
          durch entsprechende Mitteilung an den Webseitenbetreiber mit Wirkung
          für die Zukunft vornehmen.
        </p>
        <h3 className="py-4">Recht auf Datenübertragbarkeit</h3>
        <p>
          Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
          Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
          die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h3 className="py-4">
          Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
        </h3>
        <p>
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
          Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
          ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
          Sitz unseres Unternehmens befindet. Der folgende Link stellt eine
          Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
        </p>
        <h3 className="py-4">
          Datenschutzerklärung für die Nutzung von Google Analytics
        </h3>
        <p>
          Diese Website nutzt den Dienst „Google Analytics“, welcher von der
          Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA)
          angeboten wird, zur Analyse der Websitebenutzung durch Nutzer. Der
          Dienst verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät
          gespeichert werden. Die durch die Cookies gesammelten Informationen
          werden im Regelfall an einen Google-Server in den USA gesandt und dort
          gespeichert. Auf dieser Website greift die IP-Anonymisierung. Die
          IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU und
          des Europäischen Wirtschaftsraum gekürzt. Durch diese Kürzung entfällt
          der Personenbezug Ihrer IP-Adresse. Im Rahmen der Vereinbarung zur
          Auftragsdatenvereinbarung, welche der Webseitenbetreiber mit der
          Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten
          Informationen eine Auswertung der Websitenutzung und der
          Websiteaktivität und erbringt mit der Internetnutzung verbundene
          Dienstleistungen. Sie haben die Möglichkeit, die Speicherung des
          Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser
          entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet,
          dass Sie auf alle Funktionen dieser Website ohne Einschränkungen
          zugreifen können, wenn Ihr Browser keine Cookies zulässt. Weiterhin
          können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies
          gesammelten Informationen (inklusive Ihrer IP-Adresse) an die Google
          Inc. gesendet und von der Google Inc. genutzt werden. Folgender Link
          führt Sie zu dem entsprechenden Plugin:{" "}
          <a
            className="linkUnderline"
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noreferrer"
          >
            Browser-Add-on zur Deaktivierung von Google Analytics.
          </a>
          <a
            className="linkUnderline"
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noreferrer"
          >
            Hier finden Sie weitere Informationen zur Datennutzung durch die
            Google Inc..
          </a>
        </p>
        <h3 className="py-4">Cookies</h3>
        <p>
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
          richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
          Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
          sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
          Rechner abgelegt werden und die Ihr Browser speichert.
        </p>
        <p>
          Die meisten der von uns verwendeten Cookies sind so genannte
          „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
          gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis
          Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
          beim nächsten Besuch wiederzuerkennen.
        </p>
        <p>
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browser
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
        </p>
        <h3 className="py-4">Server-Log-Files</h3>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log Files, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </p>
        <ul>
          <li>Browserty/Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifeden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
        </ul>
        <p>
          Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
          Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen,
          wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt
          werden.
        </p>
        <h3 className="py-4">Kontaktformular</h3>
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter.
        </p>
        <h3 className="py-4">Änderungen der Datenschutzbestimmungen</h3>
        <p>
          Der Webseitenbetreiber behält sich vor, diese Datenschutzerklärung
          gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen
          Anforderungen entspricht oder um Änderungen der Leistungen in der
          Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer
          Services. Für Ihren erneuten Besuch gilt dann die neue
          Datenschutzerklärung.
        </p>
        <h3 className="py-4">Fragen an den Webseitenbetreiber</h3>
        <p className="pb-3">
          Wenn Sie Fragen zum Datenschutz haben, schreiben bitte eine E-Mail an
          den Webseitenbetreiber:<br></br>
          Diagnose Berlin<br></br>
          Raphael Jung<br></br>
          Gustav-Adolf-Straße 151 <br></br>
          13086 Berlin<br></br>
          info@diagnose-berlin.de
        </p>
      </section>
    </main>
  );
}
