import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AnimatedRoutes from "./components/AnimatedRoutes";

import { useConsent } from "react-hook-consent";
//import styles
import "./styles/App.css";
import "./styles/burger-menu.css";
import "react-hook-consent/dist/styles/style.css";

function App() {
  const [measures, setMeasures] = useState({});
  const { toggleBanner } = useConsent();

  useEffect(() => {
    toggleBanner();
  }, []);

  return (
    <div className="App">
      <Header />
      <AnimatedRoutes measures={measures} setMeasures={setMeasures} />
      <Footer />
    </div>
  );
}

export default App;
