import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import LandingPage from "../pages/LandingPage";
import Errorpage404 from "../pages/Errorpage404";
import Height from "../pages/Height";
import InseamLength from "../pages/InseamLength";
import Impressum from "../pages/Impressum";
import DataProtection from "../pages/DataProtection";
import TorsoLength from "../pages/TorsoLength";
import ArmLength from "../pages/Armlength";
import ShoulderWidth from "../pages/ShoulderWidth";
// import Results from "./pages/Results";
// import Pay from "./pages/Pay";

export default function AnimatedRoutes({ measures, setMeasures }) {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait" initial={true}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/height"
          element={<Height measures={measures} setMeasures={setMeasures} />}
        />
        <Route
          path="/inseamlength"
          element={
            <InseamLength measures={measures} setMeasures={setMeasures} />
          }
        />
        <Route
          path="/armlength"
          element={<ArmLength measures={measures} setMeasures={setMeasures} />}
        />
        <Route
          path="/torsolength"
          element={
            <TorsoLength measures={measures} setMeasures={setMeasures} />
          }
        />
        <Route
          path="/shoulderwidth"
          element={
            <ShoulderWidth measures={measures} setMeasures={setMeasures} />
          }
        />
        {/* <Route path="/result" element={<Results />} />
  <Route path="/pay" element={<Pay />} /> */}
        <Route path="/imprint" element={<Impressum />} />
        <Route path="/privacypolicy" element={<DataProtection />} />
        <Route path="/*" element={<Errorpage404 />} />
      </Routes>
    </AnimatePresence>
  );
}
