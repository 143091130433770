import { Link } from "react-router-dom";
import { useConsent, ConsentContext } from "react-hook-consent";

export default function Footer() {
  const { toggleBanner } = useConsent(ConsentContext);
  return (
    <footer className="container-fluid d-flex justify-content-between align-items-center overflow-visible p-0">
      <div className="container">
        <div className="row">
          <div className="col-11 col-sm-6 p-5 overflow-visible">
            <img src="/images/Pedalytics_Logo.png" height="50"></img>
          </div>

          <div className="col-11 col-sm-5 p-3 overflow-visible d-flex flex-column justify-content-evenly align-items-end">
            <Link className="py-2" to="/">
              Home
            </Link>
            <Link className="py-2" to="/imprint">
              Imprint
            </Link>
            <Link className="py-2" to="/privacypolicy">
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-11 col-sm-6 p-5 overflow-visible d-flex">
            <p className="px-3 footerBottom">&copy; Pedalytics 2024</p>
            <button
              id="cookieButton"
              className="btn btn-link"
              onClick={() => toggleBanner()}
            >
              cookie settings
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
}
