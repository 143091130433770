import InputFormVideo from "../components/InputFormVideo";
import { ContinueButton, BackButton } from "../components/Buttons";
import { motion } from "framer-motion";
import Scroll2Top from "../components/Scroll2Top";

export default function ArmLength({ measures, setMeasures }) {
  const data = {
    id: 1,
    name: "armlength",
    unit: ["cm", "inch"],
    placeholderMetric: "e.g. 59",
    placeholderImperial: "e.g. 23.2",
    title: "arm length",
    video: (
      <iframe
        src="https://www.youtube.com/embed/5BIIzvbOhrY?si=A8zjZEPYgKnKM6ok"
        title="How to measure your arm length"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
    placeholderImage: "images/Screenshot_armlength.jpg",
    videoUrl: "https://www.youtube.com/watch?v=5BIIzvbOhrY",
    next: <ContinueButton path={"/torsolength"} />,
  };

  return (
    <motion.main
      initial={{ opacity: 0, transition: { duration: 0.3 } }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <Scroll2Top />
      <BackButton side={"right"} />
      <InputFormVideo data={data} />
      <div className="pageBackground"></div>
    </motion.main>
  );
}
