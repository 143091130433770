import { handleInput } from "../utils/functions";
import { useState, useEffect } from "react";

export default function InputForm({ data }) {
  const [inputValue, setInputValue] = useState("");
  const [unit, setUnit] = useState("cm");

  useEffect(() => {
    const storedValue = sessionStorage.getItem(data.name);
    if (storedValue !== null) {
      setInputValue(storedValue);
    }

    const storedKey = sessionStorage.getItem(`${data.name}unit`);
    if (storedKey !== null) {
      setUnit(storedKey);
    }
  }, []);

  return (
    <>
      <section
        id="singlePage"
        className="container d-flex flex-column justify-content-center align-items-center"
      >
        <div
          className="d-flex flex-column align-items-center justify-content-evenly "
          style={{ height: "100%" }}
        >
          <h2>
            {`What's your ${data.title}`}
            <span className="questionmark">?</span>
          </h2>
          <div className="col-5">
            <fieldset
              className="mx-auto"
              required
              aria-label={`select a unit for your ${data.name}`}
            >
              <div className="d-flex align-items-center">
                <input
                  id="metric"
                  type="radio"
                  value={`${data.unit[0]}`}
                  name={`${data.name}unit`}
                  checked={unit === `${data.unit[0]}`}
                  onChange={(e) => {
                    handleInput(e, setUnit);
                  }}
                ></input>
                <label htmlFor={`${data.unit[0]}`}>{`${data.unit[0]}`}</label>
              </div>

              <div className="d-flex align-items-center">
                <input
                  id="imperial"
                  type="radio"
                  value={`${data.unit[1]}`}
                  name={`${data.name}unit`}
                  checked={unit === `${data.unit[1]}`}
                  onChange={(e) => {
                    handleInput(e, setUnit);
                  }}
                ></input>
                <label htmlFor={`${data.unit[1]}`}>{`${data.unit[1]}`}</label>
              </div>
            </fieldset>
          </div>

          <input
            type="number"
            style={{ width: "250px" }}
            placeholder={
              unit === "cm" ? data.placeholderMetric : data.placeholderImperial
            }
            aria-label={`${data.name}`}
            name={`${data.name}`}
            value={inputValue}
            required
            onChange={(e) => {
              handleInput(e, setInputValue);
            }}
          ></input>
        </div>
        <div className="d-flex justify-content-center p-4 overflow-visible">
          {data.next}
        </div>
      </section>
    </>
  );
}
