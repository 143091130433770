import InputForm from "../components/InputForm";
import { ContinueButton, BackButton } from "../components/Buttons";
import { motion } from "framer-motion";
import "../styles/App.css";
import Scroll2Top from "../components/Scroll2Top";

export default function Height() {
  const data = {
    id: 1,
    name: "height",
    unit: ["cm", "feet"],
    title: "body height",
    placeholderMetric: "e.g. 175",
    placeholderImperial: "e.g. 5.9",
    video: "",
    next: <ContinueButton path={"/inseamlength"} />,
  };

  return (
    <motion.main
      initial={{ opacity: 0, transition: { duration: 0.3 } }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <Scroll2Top />
      <BackButton side={"right"} />
      <InputForm data={data} />
      <div className="pageBackground"></div>
    </motion.main>
  );
}
